<template>
  <div>
    <AppBar :title="$t('register.title')"/>
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-card-title>
        <v-chip v-if="filterStart || filterFinish" label>
          <span v-if="filterStart">{{ filterStart }}</span>
          <span v-if="filterStart && filterFinish" class="mx-2"> - </span>
          <span v-if="filterFinish">{{ filterFinish }}</span>
        </v-chip>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="logsShow" v-model="valid">
          <v-row align="center">
            <v-col cols='2'>
              <v-menu
                v-model="menuDateFrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="start"
                  :label="$t('date.dateFrom')"
                  persistent-hint
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  :rules="required"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="start"
                no-title
                @input="menuDateFrom = false"
              ></v-date-picker>
            </v-menu>
            </v-col>
          <v-col cols='2'>
            <v-menu
              v-model="menuDateTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="finish"
                  :label="$t('date.dateTo')"
                  persistent-hint
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="required"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="finish"
                no-title
                @input="menuDateTo = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
              <v-btn @click="getLogs" color="secondary" depressed rounded>{{ $t('report.show') }}</v-btn>
          </v-col>
        </v-row>
          </v-form>
      </v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 150px" class="text-left">{{ $t('date.title') }}</th>
              <th style="width: 200px" class="text-left">{{ $t('changeHistory.user') }}</th>
              <th>{{ $t('changeHistory.name') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(log, i) in logs" :key="i">
              <td style="width: 150px">{{ log.date | dateFormat('DD.MM.YYYY HH:mm') }}</td>
              <td class="text-left">{{ log.actionPerformer }}</td>
              <td >{{ log.actionName }}</td>
              <td>{{ log.entityName }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
export default {
  name: 'Logs',
  data () {
    return {
      valid: true,
      logs: null,
      start: null,
      finish: null,
      filterStart: null,
      filterFinish: null,
      menuDateFrom: false,
      menuDateTo: false,
      required: [(v) => !!v || this.$t('validation.fieldRequired')]
    }
  },
  computed: {
    totalCount () {
      return this.costs.shoppingCenterCosts.map(item => item.cardsChargeAmount).reduce((prev, curr) => prev + curr, 0)
    }
  },
  methods: {
    async getLogs () {
      this.$refs.logsShow.validate()

      if (!this.valid) return
      this.loading = true
      try {
        const { data } = await Service.get(`/Logs?From=${this.start}&To=${this.finish}`)
        this.filterStart = this.start
        this.filterFinish = this.finish
        this.logs = data
      } catch (error) {}
      this.loading = false
    }
  }
}
</script>
